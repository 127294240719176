import React from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import GaugeChart from 'react-gauge-chart';
import Tile from '../../containers/tile';
import ContactCreditScore from './contactCreditScore';

const styles = (theme) => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    table: {
        marginTop: theme.spacing.unit * 2,
        border: '1px solid #e0e0e0',
    },
    statusCell: {
        maxWidth: 250,
        whiteSpace: 'normal',
    },
    lightGrey: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    gaugeContainer: {
        width: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    creditScoreCell: {
        minWidth: 150,
        whiteSpace: 'normal',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        '&:first-of-type': {
            paddingLeft: 16,
        },
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.common.white,
        paddingLeft: 8,
        paddingRight: 4,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
        '&:first-of-type': {
            paddingLeft: 16,
        },
        paddingRight: 4,
    },
    // paddingLeft: 8,
}))(TableCell);

class ContactDuplicates extends React.Component {
    state = {
    };

    componentDidMount() {
    }

    handleDuplicateClick = (link) => {
        // if (Array.from(link)[0] === '/') {
        //     this.props.history.push(link);
        //     return;
        // }
        window.open(link);
    };

    render() {
        const {
            classes,
            contactLoading,
            contactDuplicatesLoading,
            duplicates,
        } = this.props;
        const {
        } = this.state;

        return (
            <Tile
                tileTitle="Duplicates"
                style
            >
                { !contactLoading && !contactDuplicatesLoading
                    ? (
                        <>
                            {duplicates.length !== 0
                                ? (
                                    <>
                                        <Typography variant="body1">
                                            Possible duplicates found
                                        </Typography>
                                        <Paper elevation={0}>
                                            <Table className={classes.table} padding="dense">
                                                <TableHead>
                                                    <TableRow>
                                                        <CustomTableCell align="left">Company/App</CustomTableCell>
                                                        <CustomTableCell align="left">Reference</CustomTableCell>
                                                        <CustomTableCell align="left">Name/Address</CustomTableCell>
                                                        <CustomTableCell align="left">Email Address</CustomTableCell>
                                                        <CustomTableCell align="left">Telephone Numbers</CustomTableCell>
                                                        <CustomTableCell className={classes.statusCell} align="left">Status</CustomTableCell>
                                                        <CustomTableCell align="center">Scheduled Payments</CustomTableCell>
                                                        <CustomTableCell align="center">Payment Risk</CustomTableCell>
                                                        <CustomTableCell align="center" className={classes.creditScoreCell}>Credit Score</CustomTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {duplicates.sort((a, b) => a.score - b.score).reverse().map((duplicate) => (
                                                        <TableRow onClick={() => this.handleDuplicateClick(duplicate.link)} className={classes.row} key={duplicate.reference}>
                                                            <CustomTableCell align="left">
                                                                <div>{duplicate.companyName}</div>
                                                                <div className={classes.lightGrey}>{duplicate.application}</div>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="left">{duplicate.reference}</CustomTableCell>
                                                            <CustomTableCell align="left">
                                                                <div>{duplicate.fullName}</div>
                                                                <div className={classes.lightGrey}>{duplicate.shortAddress}</div>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="left">{duplicate.emailAddress}</CustomTableCell>
                                                            <CustomTableCell align="left">
                                                                <div>{duplicate.telephoneNumber1}</div>
                                                                <div>{duplicate.telephoneNumber2}</div>
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.statusCell} align="left">
                                                                {duplicate.status}
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <div>{`${duplicate.authorisedPayments}/${duplicate.paymentAttempts}`}</div>
                                                                <div className={classes.lightGrey}>
                                                                    {`${duplicate.authorisedPayments > 0
                                                                        ? ((duplicate.authorisedPayments / duplicate.paymentAttempts) * 100).toFixed(0)
                                                                        : 0
                                                                    }%`}

                                                                </div>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <div className={classes.gaugeContainer}>
                                                                    <GaugeChart
                                                                        id={`payment-gauge-chart-${duplicate.reference}`}
                                                                        cornerRadius={1}
                                                                        hideText
                                                                        nrOfLevels={5}
                                                                        percent={duplicate.authorisedPayments > 0
                                                                            ? 1 - (duplicate.authorisedPayments / duplicate.paymentAttempts)
                                                                            : 0}
                                                                    />
                                                                </div>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="left" className={classes.creditScoreCell}>
                                                                <ContactCreditScore
                                                                    internalCreditScore={duplicate.internalCreditScore}
                                                                    reference={duplicate.reference}
                                                                />
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </>
                                )
                                : (
                                    <Typography variant="body1">
                                        No duplicates found
                                    </Typography>
                                )}
                        </>

                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </Tile>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactDuplicates));
